import {graphql, useStaticQuery} from "gatsby";


export const useImgAttributionQuery = () => {
    const data = useStaticQuery(graphql`
    query AttributionQuery {
        mdx(frontmatter: {slug: {eq: "/attribution"}}) {
            frontmatter {
                slug
                title
                image{
                    childImageSharp {
                        gatsbyImageData
                    }
                 }
                externalPics{
                    text, 
                     image{
                        childImageSharp {
                            gatsbyImageData
                        }
                     }
                     objectFit
                     link
                }
            }
        }
    }`)

    return data;
}