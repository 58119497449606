import React from "react"
import SEO from "../components/SEO/SEO"
import PageBody from "../components/PageBody/PageBody";
import {useImgAttributionQuery} from "../hooks/useImgAttributionQuery";
import LinkTextUnderneathArea
    from "../components/CARDS/LinkPictures/TextUnderneath/LinkTextUnderneathArea/LinkAreaTextUnderneath";

const AttributionPage = () => {

    const { mdx: {frontmatter : attributionData}} = useImgAttributionQuery();

    return(
        <PageBody pageData={attributionData}>
            <LinkTextUnderneathArea links_area_list={attributionData.externalPics}/>
        </PageBody>
    )

}

export const Head = () => <SEO title="Bild-Nachweise" />

export default AttributionPage