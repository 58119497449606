import React from "react";
import {StyledImg, Wrapper,CardTextWrapper, CardText} from "./LinkTextUnderneath.styles";

const LinkTextUnderneath = ({image, link, text, objectFit, item_class_name}) => (

    <a href={link} target="_blank">
        <Wrapper className={item_class_name}>
            <StyledImg
                image={image.childImageSharp.gatsbyImageData}
                alt={`Bild ${text}`}
                width={720}
                imgStyle={{objectFit: objectFit}}
            />
            <CardTextWrapper>
                <CardText>{text}</CardText>
            </CardTextWrapper>
        </Wrapper>
    </a>
)

export default LinkTextUnderneath;