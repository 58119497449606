import React from "react";
import {Wrapper, SectionWrapper} from "./GridArea.styles";

const GridArea = ({children}) => {

    return(
        <Wrapper>
            <SectionWrapper>
                {children}
            </SectionWrapper>
        </Wrapper>
    )
}

export default GridArea;