import React from "react";
import LinkTextUnderneath from "../LinkTextUnderneath/LinkTextUnderneath";
import GridArea from "../../../../POSITIONING/GridArea/GridArea";

const LinkTextUnderneathArea = ({links_area_list, item_class_name}) => {

    return(
        <GridArea>
            {links_area_list.map(item =>
                <LinkTextUnderneath
                    image={item.image}
                    objectFit={item.objectFit}
                    link={item.link}
                    text={item.text}
                    key={item.link}
                    item_class_name={item_class_name}
                />
                )}
        </GridArea>
    )
}

export default LinkTextUnderneathArea;